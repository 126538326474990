<template>
  <div class="view-app-download">
  </div>
</template>

<script>
export default {
  name: 'AppDownload',

  data: () => ({
  }),

  created() {
    // Se il browser è mobile, reindirizza alla pagina di download dell'app
    // Per i dispositivi iOS manda alla pagina di download dell'app su App Store
    // Per i dispositivi Android manda alla pagina di download dell'app su Google Play
    // Tutti gli altri vanno alla home
    if (this.isOS) {
      window.location.href = 'https://apps.apple.com/it/app/keim-easy/id6457364728';
    } else if (this.isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.keimeasy.app';
    } else {
      this.$router.replace('/', () => {});
    }
  },

  computed: {
    isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    },

    isAndroid() {
      return navigator.userAgent.match(/android/i);
    },
  },

  methods: {
  },
};
</script>

<style>
</style>
